.emailMessageConatiner {
    width: 100%;
    display: flex;

    p {
        max-width: 100%;
        margin-right: 3px;
    }
}
.emailMessageConatiner .emailMessage {
    // max-width: 65%;
    display: flex;
    p {
        margin-right: 3px;
    }
}
.emailCreatedAt {
    margin-left: 5px;
    font-size: 12px;
}
.emailRichTextEditorCustomControls {
    display: inline-block;
    margin: 0 3px 0 0;
    padding: 1px 2px;
    height: 28px;
    line-height: 22px;
    box-sizing: border-box;
    background: none #fdfdfd;
    border: 1px solid #999;
    border-radius: 2px;
    color: #333;
    cursor: pointer;
    white-space: nowrap;
}
.createEmailContainer {
    padding: 12px;
    .container {
        position: relative;
        // width: 50%;
        // max-width: 300px;
        padding: 2px;
        text-align: center;
        color: var(--secondary);
    }

    .image {
        width: 100%;
        max-height: 250px;
    }

    .overlay {
        position: absolute;
        height: 50px;
        margin: 20px;
        right: 0;
        bottom: 0;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); /* Black see-through */
        color: #f1f1f1;
        width: 50px;
        transition: 0.5s ease;
        opacity: 0;
        color: white;
        font-size: 20px;
        text-align: center;
    }

    .container:hover .overlay {
        opacity: 1;
    }

    .deleteIcon {
        color: var(--white);
    }

    .fileContainer {
        position: relative;
        // width: 80%;
        padding: 5px 0;
        p {
            margin-top: 5px;
            padding: 6px;
            border-top: 1px solid lightgrey;
        }
    }

    .file {
        display: block;
        width: 100%;
        height: 100px;
    }

    .fileOverlay {
        padding: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: #eee;
        font-weight: 600;
        word-wrap: break-word;
        display: flex !important;
        justify-content: space-between !important;
        flex-direction: column !important;

    }

    .actionButton {
        display: flex;
        justify-content: flex-end;
    }

    .fileContainer:hover .fileOverlay {
        opacity: 1;
    }

    .text {
        // color: white;
        background-color: darkgrey;
        border: 1px solid black;
        padding: 8px;
        // position: absolute;
        // bottom: 20%;
        // right: 20%;
        // -webkit-transform: translate(20%, 20%);
        // -ms-transform: translate(20%, 20%);
        // transform: translate(20%, 20%);
        text-align: center;
        a {
            height: 20px;
        }
    }
}
.emailAttachments {
    text-decoration: underline;
}
.emailAttachments:hover {
    color: var(--link);
}

.previewImageContainer {
    text-align: center;
    // min-width: 70%;
    background: transparent;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;

    img {
        border: 1px solid #999;
        padding: 0;
        display: block;
        margin: auto;
        max-height: 100%;
        max-width: 90%;
    }
}
