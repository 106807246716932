.brandHeader {
  display: flex;
  grid-gap: 4px;
  align-items: center;
}
.brandHeaderSearchBar {
  display: block;
}
.updatedAtTime,
.createdAtTime {
  font-size: 0.6 rem;
  line-height: 1 rem;
  display: block;
  font-weight: lighter;
}
.updateBy,
.createBy {
  font-size: 0.8rem;
  line-height: 1rem;
  display: block;
}
.brandHeaderAddBtnActionBtnGroup {
  display: flex;
  grid-gap: 15px;
}
.brandHeaderDropdown
  .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl {
  color: #09445a;
  font-size: 18px;
  width: 100% !important;
}
.brandHeaderDropdown
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  font-size: 22px;
  color: #414c50;
}
.brandHeader.brandHeader-mobile
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  padding-left: 18px !important;
  padding-right: 18px !important;
  height: 34px !important;
}
/* Button.brandHeaderAddBtn{
  padding-left: 18px;
  padding-right: 18px;
  width: 99px;
  height: 34px;
} */
Button.brandHeaderActionBtn {
  padding-left: 18px;
  padding-right: 18px;
  height: 34px;
}
.MuiButton-iconSizeMedium > *:first-child {
  font-size: 18px !important;
}
.brand-header-inner-container
  .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
  .MuiBox-root.MuiBox-root-40.brandHeader {
  display: flex;
  grid-gap: 5px;
}
@media only screen and (max-width: 1024px) {
  .brandHeader {
    margin-top: 0.8rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .brandHeader {
    display: block;
    grid-gap: 8px;
    flex-direction: column;
    margin-top: 0;
  }

  .brandHeaderAddBtnActionBtnGroup {
    margin-top: 15px;
    width: 100%;
  }
  .brandHeaderSearchBar {
    display: block;
  }
  .brand-header-inner-container div.brandHeader {
    display: block;
  }
  .MuiButton-containedPrimary.brandHeaderAddBtn,
  .MuiButton-outlined.Mui-disabled.brandHeaderActionBtn,
  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.brandHeaderActionBtn {
    width: 100% !important;
    /* display:inline; */
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    width: 100% !important;
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    width: 100% !important;
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl
    .MuiInput-formControl {
    width: 100% !important;
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    width: 100% !important;
  }
  .brand-header-inner-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiBox-root.MuiBox-root-40.brandHeader {
    display: block;
  }
}

.custom-grid {
  width: 100%;
  border: 2px solid #d4d6d7;
  padding: 3px 2px;
}
.makeStyles-root-51 {
  border: 2px solid #aeb0b1ad;
}
/* .MuiDataGrid-root .MuiDataGrid-mainGridContainer {
    border: 2px solid #aeb0b1ad;
    border-radius: 12px;
} */

.brand-name-Link {
  color: #0645ad;
  cursor: pointer;
}

.brand-name-Link:hover {
  text-decoration: underline;
}

.listing-grid {
  padding: 5px;
  height: calc(100vh - 161px);
}
@media only screen and (max-width: 714px) {
  .brand-header-inner-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiBox-root.MuiBox-root-40.brandHeader {
    width: 100%;
  }
  .brand-header-inner-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiGrid-root.MuiGrid-item,
  .brandHeader.brandHeader-mobile {
    width: 100%;
  }
  .brandHeader.brandHeader-mobile .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    width: 100% !important;
  }
  .brandHeaderBrandTab,
  .brandHeaderActiveBrandTab,
  .brandHeaderInactiveBrandTab {
    margin: 0.6rem auto !important;
  }
  .brand-header-inner-container div.brandHeader {
    width: 100%;
  }
  .brandHeaderDropdown
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl
    .MuiInput-formControl {
    width: 100% !important;
  }

  .brandHeader.brandHeader-mobile {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 425px) {
  .brand-header-inner-container .MuiGrid-root.MuiGrid-item {
    width: 100%;
  }
  .brandHeader.brandHeader-mobile .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }
}
@media only screen and (max-width: 375px) {
  #demo-simple-select-outlined {
    width: 230px !important;
  }
}

@media only screen and (max-width: 768px) {
  .brandHeader {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }
  .headerLogo {
    font-size: 1rem;
  }
  .listing-grid {
    height: calc(100vh - 285px);
  }
}
