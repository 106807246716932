:root {
  --space1: 0.25rem;
  --space2: 0.5rem;
  --space3: 1rem;
  --space4: 1.5rem;
  --space5: 3rem;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-stop:hover {
  cursor: no-drop;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.d-flex {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.vertical-align-top {
  vertical-align: top;
}

.text-wrap {
  white-space: normal;
}

.word-break {
  word-break: break-word;
}

.gap-1 {
  gap: var(--space1) !important;
}

.gap-2 {
  gap: var(--space2) !important;
}

.gap-3 {
  gap: var(--space3) !important;
}

.gap-4 {
  gap: var(--space4) !important;
}

.gap-5 {
  gap: var(--space5) !important;
}

$max: 50;
$offset: 1;
$unit: 'px'; // Feel free to change the unit.
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}

// Margins
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.mx-', 'margin');
// Paddings
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pr-', 'padding-right');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.pl-', 'padding-left');
@include list-loop('.px-', 'padding');
