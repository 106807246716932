.adminHeaderFilter {
  display: flex;
  grid-gap: 20px;
  /* align-items: center; */
}
.adminHeaderFilter
  .adminsHeader-searchbar
  .MuiFormControl-root.MuiTextField-root {
  width: 300px !important;
}
.adminsheader-autocomplete {
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
.adminsHeader-searchbar
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  padding: 0 0 0 10px !important;
}

Button.adminsheader-addbtn {
  /* padding: 6px 16px; */
  width: 99px;
}
/* Button.adminsheader-actionbtn {
  padding: 6px 16px;
} */

@media only screen and (max-width: 1024px) {
  .adminHeaderFilter {
    margin-top: 0.6rem;
  }
}
@media only screen and (max-width: 768px) {
  .adminsHeader {
    grid-gap: 8px;
    flex-direction: column;
  }
  .adminheader-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiGrid-root.MuiGrid-item {
    width: 100% !important;
  }
  .adminHeaderFilter {
    width: 100%;
    margin-top: 1rem;
  }
  .adminHeaderFilter {
    display: block;
  }
  .adminHeaderFilter .adminsheader-autocomplete {
    width: 100% !important;
    display: inline-block;
    margin: 0.8rem 0rem;
  }
  .adminHeaderFilter .adminsHeader-searchbar {
    display: inline-block;
    width: 100% !important;
  }
  .adminHeaderFilter
    .adminsHeader-searchbar
    .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }
  .adminHeaderFilter .adminsheader-addbtn {
    width: 48.5%;
    margin-right: 1.5%;
  }
  .adminHeaderFilter .adminsheader-actionbtn {
    width: 48.5%;
    margin-left: 1.5%;
  }
  .adminheader-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiBox-root.MuiBox-root-38.brandHeader {
    display: block;
  }
}
@media only screen and (max-width: 704px) {
  .adminHeaderFilter {
    margin-top: 0.4rem;
  }
}
