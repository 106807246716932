.userHeaderFilter {
  display: flex;
  grid-gap: 20px;
  /* align-items: center; */
}
.userHeaderFilter
  .usersHeader-searchbar
  .MuiFormControl-root.MuiTextField-root {
  width: 300px !important;
}
.usersheader-autocomplete {
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
.usersHeader-searchbar
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  padding: 0 0 0 10px !important;
}

Button.usersheader-addbtn {
  /* padding: 6px 16px; */
  width: 99px;
}
/* Button.usersheader-actionbtn {
  padding: 6px 16px;
} */

@media only screen and (max-width: 1024px) {
  .userHeaderFilter {
    margin-top: 0.6rem;
  }
}
@media only screen and (max-width: 768px) {
  .usersHeader {
    grid-gap: 8px;
    flex-direction: column;
  }
  .userheader-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiGrid-root.MuiGrid-item {
    width: 100% !important;
  }
  .userHeaderFilter {
    width: 100%;
    margin-top: 1rem;
  }
  .userHeaderFilter {
    display: block;
  }
  .userHeaderFilter .usersheader-autocomplete {
    width: 100% !important;
    display: inline-block;
    margin: 0.8rem 0rem;
  }
  .userHeaderFilter .usersHeader-searchbar {
    display: inline-block;
    width: 100% !important;
  }
  .userHeaderFilter
    .usersHeader-searchbar
    .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }
  .userHeaderFilter .usersheader-addbtn {
    width: 48.5%;
    margin-right: 1.5%;
  }
  .userHeaderFilter .usersheader-actionbtn {
    width: 48.5%;
    margin-left: 1.5%;
  }
  .userheader-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiBox-root.MuiBox-root-38.brandHeader {
    display: block;
  }
}
@media only screen and (max-width: 704px) {
  .userHeaderFilter {
    margin-top: 0.4rem;
  }
}
