:root {
  --primary: #003f57;
  --primary-light: #154e6a;
  --logoColor: #288c8c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Nunito Sans, sans-serif !important;
}


.login-bg {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #ffffff;
  background-image: url(./../assets/svg/bg1.svg);
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23d0d6d9' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23a2adb3' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%2373858c' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23455c66' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23163340' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #ddd;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ada7a7cc;
}

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #9e9ea5;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
  border-color: #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

/* MAC like scrollbar - End */

a {
  text-decoration: none;
  color: inherit;
}

.MuiToolbar-root {
  min-height: 55px !important;
}

.bg-danger {
  background-color: #fdf8f8 !important;
  color: #36274e !important;
  border: 1px solid #ff5b5b !important;
  border-radius: 5px !important;
}

.bg-success {
  color: #077069 !important;
  background-color: #f8fffe !important;
  border: 1px solid #7dbbbe !important;
  border-radius: 3px !important;
  padding: 0 5px !important;
}

.bg-primary {
  background-color: #f8fafa !important;
  color: #094b48 !important;
  border: 1px solid #0a5552 !important;
  border-radius: 5px !important;
}

.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
}

div.bg-danger span.MuiChip-labelSmall::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #f61010;
  margin-right: 5px;
  border-radius: 50%;
}

div.bg-primary span.MuiChip-labelSmall::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--primary);
  /* border: 1px solid #077069; */
  margin-right: 5px;
  border-radius: 50%;
}

/* Remove outline from Data grid cells when in focus */
.MuiDataGrid-cell:focus,
.MuiDataGrid-colCell:focus {
  outline: none !important;
}

.makeStyles-formControl-9 {
  width: 100%;
}

/* orgchart styles */
.orgchart-container {
  border: none !important;
}

.orgchart {
  background: none !important;
}

.orgchart {
  display: flex;
  justify-content: center;
}

/* orgchart main styles */

.cursor-pointer:hover {
  cursor: pointer;
}

.orgchart {
  display: flex;
  justify-content: center;
}

.orgchart ul {
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
}

.orgchart li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 10px;
  transition: all 0.5s;
}

.orgchart li:only-child {
  padding-top: 0;
}

.orgchart li .user {
  min-width: none;
  max-width: 280px;
  width: 280px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-decoration: none;
  color: #666;
  display: inline-block;
  padding: 20px 10px;
  transition: all 0.5s;
  background: rgb(197, 226, 230);
  min-width: 180px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.orgchart li .user:hover,
.orgchart li .user:hover+ul li .user {
  background: #b5d5ef;
  color: #002a50;
  transition: all 0.15s;
  transform: translateY(-5px);
  box-shadow: inset 0 0 0 3px #76b1e1, 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.orgchart li .user:hover img,
.orgchart li .user:hover+ul li .user img {
  box-shadow: 0 0 0 5px #4c99d8;
}

.orgchart li .user>div,
.orgchart li .user>a {
  font-size: 12px;
}

.orgchart li .user img {
  position: absolute;
  left: 5% !important;
  top: 10% !important;
  margin: 0 auto;
  max-width: 60px;
  max-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #aaa;
}

.orgchart li .user .name {
  width: 100%;
  padding-left: 26%;
  text-align: left !important;
  font-size: 16px;
  color: rgb(125, 153, 160);
  font-weight: bold !important;
}

.orgchart li .user .role {
  font-weight: bold;
  color: rgb(38, 83, 92) i !important;
  font-size: 15px i !important;
  text-align: left !important;
  padding-left: 26%;
}

.orgchart li .user .manager {
  font-size: 15px;
  color: #b21e04;
}

.orgchart ul {
  padding-top: 0;
}

.orgchart li {
  padding-top: 0px;
}

.orgchart>ul>li>ul li::before {
  border-top: 4px solid rgb(169, 173, 176) !important;
}

.orgchart>ul>li>ul li>.oc-node::before {
  background: rgb(169, 173, 176) !important;
  width: 5px;
  height: 14px;
}

.orgchart ul li .oc-node::after {
  background: rgb(169, 173, 176) !important;
  width: 5px;
  height: 14px;
}

.orgchart-container {
  overflow: hidden !important;
}

.orgchart ul li .oc-node::after {
  background: rgb(169, 173, 176) !important;
}

.orgchart-container {
  transform: matrix(0.7);
}

.MuiBox-root .MuiBox-root-21 {
  width: 100%;
}

.myChart {
  transform: scale(0.7);
}

.orgchart-container {
  width: 100%;
}

.iconArea {
  float: right;
  padding-top: 10px !important;
  transform: translate(-80%, 8px) i !important;
  padding-left: 10px !important;
}

MuiSvgIcon-root {
  margin-right: 18px !important;
  fill: rgb(15, 79, 95) !important;
}

.form-header:nth-child(1) {
  margin-top: 0.5rem;
}

.form-header-style {
  border: none;
  margin-top: 0.5rem;
  background: #fafafa;
  margin-bottom: 1.5rem;

  .form-box {
    padding: 0.8rem;
    margin: 0;
    padding-top: 1.2rem;
  }

  .form-header {
    font-size: 1rem;
    padding: -1px 18px;
    font-weight: 600;
    padding: 0;
    background: white;
    color: #343232;
    padding-left: 10p;

    &:before {
      content: '\A';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #333232;
      display: inline-block;
      margin-right: 6px;
    }
  }
}

// padding: 12px;
// margin: 0;

.custom-alert-dialog .alert-dialog-title {
  /* background: #ffbb33; */
  /* color: #fff; */
  background: #fff;
  border-bottom: 1px solid lightgray;
}

.custom-alert-dialog .MuiDialog-container .MuiDialogActions-root {
  border-top: 1px solid lightgray;
  background: #fff;
}

.roles-table>tbody>tr>td {
  padding: 0px 15px;
  border-bottom: 0px;
}

.header-panel {
  color: var(--primary);
  background-color: #f7f7f7;
  border-bottom: 1px solid #ecf1f3;
  border-top: 1px solid #ecf1f3;
  padding: 8px 10px;
}

.listingHeader {
  font-size: 1.35rem;
  font-weight: bold;
  color: var(--primary);
}

.headerLogo {
  font-size: 1.15rem;
  color: var(--primary);
  margin-left: 4px;
}

.block-divider {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-family: 'Barlow', sans-serif !important;
}

.header-detail-box {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 1px 3px 3px #c2bbbb;
  width: 11rem;
  padding: 11pz;
  margin: 0.5rem;

  .header-text {
    padding: 2px;
    background: #f5f5f5;
    color: #3a2a5b;
    font-size: 0.9rem;
  }

  .detail-text {
    color: #17053e;
    font-size: 1rem;
    padding: 10px;
  }
}

.accordian-box {
  border: 1px solid #cdcbd6 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: #eeebf6 !important;
}

.accordian-header {
  border-bottom: 1px solid #cdcbd6 !important;
  font-weight: bold !important;
}

.detail-box {
  border: 1px solid #ece9e9;
  border-radius: 0;
  background: #fafafa;
  padding: 10px !important;
}

.w-100 {
  width: 100%;
}

.custom-tooltip:hover {
  cursor: help;
}

//Mobile Responsive
@media only screen and (max-width: 768px) {
  .brandHeader {
    #header-search {
      width: 100% !important;
    }
  }

  .mobile-header {
    width: 100%;
  }

  .listing-grid {
    height: calc(100vh - 287px);
  }
}

.imgLogin {
  height: 52vh;
  margin-top: 0px;
}

.loginSidebar {
  height: 100%;
  background-image: url(./../assets/svg/bg2.svg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: right 0 center;
}

.loginHeader {
  color: #102414;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 20px;
  width: 74%;
}

.logo-bg-color {
  background-color: var(--logoColor) !important;
  color: white !important;

  &:disabled {
    background-color: lightgray !important;
    color: var(--primary);
    cursor: not-allowed;
  }
}