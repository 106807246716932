.custom-grid {
  width: 100%;
  border: 2px solid #d4d6d7;
  padding: 3px 2px;
}

.makeStyles-root-51 {
  border: 2px solid #aeb0b1ad;
}

/* .MuiDataGrid-root .MuiDataGrid-mainGridContainer {
    border: 2px solid #aeb0b1ad;
    border-radius: 12px;
} */

.role-name-Link {
  color: #0645ad !important;
  cursor: pointer;
}

.role-name-Link:hover {
  text-decoration: underline;
}

.globalRoleHeader {
  display: flex;
  grid-gap: 20px;
}
.globaluser-header-inner-container {
  display: flex;
  grid-gap: 20px;
}

.brandHeaderActiveBrandTab {
  grid-area: 'active';
  margin-right: 1rem;
  margin-left: 1rem;
}
.brandHeaderInactiveBrandTab {
  grid-area: 'inactive';
  margin: 0 auto;
}
.globaluser-select-brand {
  width: 300px;
}
.globaluser-header-inner-container
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  padding: 0 0 0px 10px !important;
}

Button.globaluser-addbtn {
  padding: 6px 16px;
  width: 99px;
}
Button.globaluser-actionbtn {
  padding: 6px 16px;
}

@media only screen and (max-width: 1024px) {
  .globaluser-header-inner-container {
    margin-top: 0.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .globalRoleHeader {
    grid-gap: 10px;
  }

  .globalRoleSreachBarDiv {
    width: 20% !important;
  }

  .globalRoleAddNewRoleDiv .globalRoleAddNewRole,
  .globalRoleActionBtnDiv .globalRoleActionBtn {
    height: 2.45rem;
  }
  .globaluser-select-brand {
    width: 700px;
    margin-top: 0.8rem;
  }
  .globaluser-header-inner-container {
    display: block;
  }
  .globaluser-header-inner-container .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }

  .globaluser-header-inner-container
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.globaluser-addbtn.MuiButton-containedPrimary {
    margin-top: 0.8rem;
    margin-right: 2%;
    width: 48% !important;
  }
  .globaluser-header-inner-container
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.globaluser-actionbtn.Mui-disabled.Mui-disabled {
    margin-top: 0.8rem;
    margin-left: 2%;
    width: 48% !important;
  }
  /* .globaluser-header-inner-container .MuiAutocomplete-root.globaluser-select-brand.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon{
  width: 325px ;
} */
  .globaluser-header-inner-container
    .globaluser-select-brand
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    width: 100% !important;
  }
  .globaluser-header-inner-container
    .MuiAutocomplete-root.globaluser-select-brand.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    display: inline-flex !important;
  }
}

@media only screen and (max-width: 480px) {
  .globalRoleHeader {
    display: block;
    grid-gap: 10px;
  }

  .globalRoleSreachBarDiv {
    width: 100% !important;
  }

  .globalRoleDropdownDiv,
  .globalRoleSreachBarDiv,
  .globalRoleAddNewRoleDiv,
  .globalRoleActionBtnDiv {
    margin-top: 0.8rem;
  }
  .globaluser-header-inner-container
    .MuiAutocomplete-root.globaluser-select-brand.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    margin: 0.8rem 0 0 0;
    display: inline-flex !important;
  }
}
@media only screen and (max-width: 425px) {
  .globaluser-header-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiBox-root.MuiBox-root-40.brandHeader {
    display: block;
    margin-top: 0;
  }
  .globaluser-header-inner-container {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 320px) {
  .globaluser-header-inner-container .MuiFormControl-root.MuiTextField-root {
    width: auto !important;
  }
}
.role-Dialog .MuiDialog-paper {
  min-width: 50%;
}

@media only screen and (max-width: 480px) {
  .tabs {
    min-width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .tabs {
    min-width: 20%;
  }
}
.global-roles-grid {
  height: calc(100vh - 200px);
}
@media only screen and (max-width: 750px) {
  .globaluser-select-brand {
    width: 685px;
  }
}
@media only screen and (max-width: 720px) {
  .globaluser-select-brand {
    width: 655px;
  }
}
@media only screen and (max-width: 700px) {
  .globaluser-select-brand {
    width: 635px;
  }
}
@media only screen and (max-width: 680px) {
  .globaluser-select-brand {
    width: 615px;
  }
}
@media only screen and (max-width: 660px) {
  .globaluser-select-brand {
    width: 595px;
  }
}
@media only screen and (max-width: 653px) {
  .globaluser-select-brand {
    width: 100%;
  }
  .globaluser-header-outter-container
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-space-between
    .MuiGrid-root.MuiGrid-item {
    width: 100%;
  }
  .globaluser-header-inner-container {
    width: 100%;
  }
}
.user-name-Link {
  color: #0645ad;
  cursor: pointer;
}

.user-name-Link:hover {
  text-decoration: underline;
}

.role-header {
  background: #eae9eb;
}
