.custom-link {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.link-text {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.pathNames {
  font-size: 20px !important;
  font-weight: bold !important;
}

.breadcrumbbox {
  border-bottom: 1px solid #f3f3f3;
  background: white;
  padding: 10px;
  border-top: 2px solid white;
  height: 47px;
  width: 100%;
}
.breadcrumb li,
.breadcrumb li p {
  margin: 0px 4px;
  font-size: 18px;
  color: #848282;
}

/*
.breadcrumb{
    list-style: none; 
    overflow: hidden; 
    font-size: 16px ;
}
.breadcrumb li { 
    float: left; 
  }
  .breadcrumb li a {
    color: white;
    text-decoration: none; 
    padding: 0 0 0 32px;
    background: #040649; 
    position: relative; 
    display: block;
    float: left;
  }

  .breadcrumb li a::after { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
/* border-bottom: 50px solid transparent;
    border-left: 30px solid #040649;
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    left: 100%;
    z-index: 2; 
  }

  .breadcrumb li a::before { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent;       
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    margin-left: 1px;
    left: 100%;
    z-index: 1; 
  }

  .breadcrumb li:first-child a {
    padding-left: 28px;
  }

  .breadcrumb li:nth-child(2) a       { background:        hsla(239deg,85%,16%,1); }
  .breadcrumb li:nth-child(2) a:after { border-left-color: hsla(239deg,85%,16%,1); }
  .breadcrumb li:nth-child(3) a       { background:        hsla(240deg,85%,18%,1); }
  .breadcrumb li:nth-child(3) a:after { border-left-color: hsla(240deg,85%,18%,1); }
  .breadcrumb li:nth-child(4) a       { background:        hsla(240deg,85%,65%,1); }
  .breadcrumb li:nth-child(4) a:after { border-left-color: hsla(240deg,85%,65%,1); }
  .breadcrumb li:nth-child(5) a       { background:        hsla(240deg,85%,75%,1); }
  .breadcrumb li:nth-child(5) a:after { border-left-color: hsla(240deg,85%,75%,1); }
  .breadcrumb li:last-child p {
    background: transparent !important;
    color: black;
    pointer-events: none;
    cursor: default;
  }
  .breadcrumb li:last-child p::after { 
    border: 0; 
  }
  .breadcrumb li:last-child {
    background: #e6e4e4;
    padding: 0 28px;
  }

  .breadcrumb li a:hover { 
    background: #0f0f2e; 
  }
  .breadcrumb li a:hover:after { 
    border-left-color: #0f0f2e !important; 
  }  */
