.set_gridbox_layout {
    height: calc(85vh - 194px);
    overflow: auto;
}
@media screen and (max-width: 960px) {
  .set_gridbox_layout {
    height: calc(85vh - 194px);
    overflow-x: hidden;
  }
  .form_grid_box{
      display: grid !important;
      grid-template-columns: 1fr;
      width: 200% !important;
      padding-bottom: 15px;
  }
  .custom_field{
      display: grid !important;
      width: 200% !important;
  }
  .addiction_field{
    display: grid !important;
    width: 200% !important;
  }
}
